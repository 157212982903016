import * as requestFromServer from "./Crud";
import {profileSlice, callTypes} from "./Slice";
import config from "../../../../../config";

const {actions} = profileSlice;

export const fetchData = (type, queryParams) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findData(type, queryParams)
    .then(response => {
      var res = response.data;
      if (res.error) {
        dispatch(actions.catchError({ error: res.error, callType: callTypes.list }));
        return;
      }
      if (type === 'plan') {
        dispatch(actions.planFetched(res.data));
      } 
    })
    .catch(error => {
      // alert(error)
      var message = error.response.data.message;
      var status_code = error.response.status;
      console.log(message, status_code)
      error.clientMessage = "Can't find data";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};


export const set_settings = (d) => dispatch => {
  dispatch(actions.dataFetched(d));
};
   

export const fetchCustomer = id => dispatch => {
  if (!id) {
    return dispatch(actions.dataFetched({ dataForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getCustomerById(id)
    .then(response => {
      const data = response.data;
      dispatch(actions.dataFetched({ dataForEdit: data }));
    })
    .catch(error => {
      error.clientMessage = "Can't find data";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteCustomer = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteCustomer(id)
    .then(response => {
      dispatch(actions.dataDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete data";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const addData = (type, dataForCreation) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .addData(type, dataForCreation)
    .then(response => {
      var res = response.data;
      // console.log(res);
      dispatch(actions.stopLoading());
      if (res.status !== 'Success' || !res.data) {
        dispatch(actions.catchError({ error: res.message ? res.message : "Error", callType: callTypes.action }));
        return;
      }
      if (type === 'settings') {
        return res;
      } else if (type === 'user') {
        return res;
      } else if (type === 'password') {
        return res;
      } else if (type === 'file') {
        return res;
      } else if (type === 'subscribe') {
        return res;
      }
    })
    .catch(error => {
      var message = error.response.data.message;
      var status_code = error.response.status;
      // console.log(message, status_code)
      // error.clientMessage = message ? JSON.stringify(message) : "Can't create data";
      error = message ? message: "Can't create data";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const clearError = () => dispatch => {
  dispatch(actions.clearError());
};

export const dangerFetched = (d) => dispatch => {
  dispatch(actions.dangerFetched(d));
};
        


export const updateUser = (data) => dispatch => {
  // dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateCustomer(data)
    .then((rs) => {
      // console.log("Hdhjsdcjdcsdcawcecvqefcec", rs);
      // dispatch(actions.dataUpdated({ data }));
    })
    .catch(error => {
      error.clientMessage = "Can't update data";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateCustomersStatus = (ids, status) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForCustomers(ids, status)
    .then(() => {
      dispatch(actions.dataStatusUpdated({ ids, status }));
    })
    .catch(error => {
      error.clientMessage = "Can't update data status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteCustomers = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteCustomers(ids)
    .then(() => {
      dispatch(actions.dataDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete data";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
