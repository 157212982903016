var date = new Date().getFullYear();

function Footer() {
    return (
      <footer class="footer_short position-relative bg-white z-index-3">
        <div class="container">
          <div class="row justify-content-md-center text-center">
            <div class="col-md-8">
              <a class="logo c-dark" href="/">
                <img src="./assets/img/logo.svg" alt=""/>
              </a>
              <div class="social--media">
                {/* <a href="#" class="btn so-link">
                  <i class="tio appstore"></i>
                </a> 
                <a href="#" class="btn so-link">
                  <i class="tio github"></i>
                </a>  */}
                {/* <a href="https://twitter.com/joinsety" target="_blank" class="btn so-link">
                  <i class="tio instagram"></i>
                </a>
                <a href="https://twitter.com/joinsety" target="_blank" class="btn so-link">
                  <i class="tio google"></i>
                </a>
                <a href="https://twitter.com/joinsety" target="_blank" class="btn so-link">
                  <i class="tio twitter"></i>
                </a> */}
                {/* <a href="#" class="btn so-link">
                  <i class="tio facebook_square"></i>
                </a>  */}
              </div>
              {/* <div class="other--links">
                <a href="mailto:help@sety.io">Support</a>
                <a href="/privacy">Privacy Policy</a>
                <a href="/terms">Terms of Use</a>
                <a href="/contact">Contact Us</a>
              </div> */}
              <div class="copyright" style = {{marginTop: 10}}>
                <p>
                  © {`${date} `}
                  <a href="/">Sety. </a>
                  All Right Reseved
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
  
  export default Footer;
  