import { Switch, Route } from "react-router-dom";

import Home from "./App/home";
import Plan from "./App/home/plan";
import Payment from "./App/home/payment";
import Residents from './App/auth/Residents'
import Thanks from './App/home/thanks'

function MainApp() {
  return (
    <Switch>
      <Route exact path='/' component={Thanks} />
      {/* <Route exact path='/home' component={Home} />
      <Route path='/plan' component={Plan} />
      <Route path='/card' component={Payment} />
      <Route path='/create-user' component={Residents} /> */}
    </Switch>
  );
}

export default MainApp;
