import React, { useState, useEffect } from "react";

import Header from "../../../components/header"
import Footer from "../../../components/footer"
import Help from "../../../components/help"
import LoadingOverlay from 'react-loading-overlay';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "./_redux/home/Actions";

function Thanks() {

  
  return (
 
    <div id="wrapper">
    <div id="content">

      {/* <Header on = {'app'} /> */}

      <main class="main_sass" data-spy="scroll" data-target="#navbar-example2" data-offset="0">
       

    

      

        <div id="triggerForm" class="spacer s0"></div>
       
        <section class="features_app_fitness padding-t-12 margin-t-5" id="Features">
            <div class="container">
              <div class="row justify-content-center text-center">
                <div class="col-lg-6">
                  <div class="title_sections">
                    <h2 class="font-s-60 font-w-700">Thank you for subscribing to Sety <span class="c-purple">Premium</span> today 🚀</h2>
                    <p>Click on the DONE button on the app to enjoy the many features we have in stock for you 💜</p>
                    {/* <p>(For users in Lagos, Nigeria only)</p> */}
                
                    {/* <a href="/" class="">
                        {`<<`} Go Back
                    </a> */}
                   
                </div>


                </div>
              </div>
            
            </div>
          </section>




     
      </main>
    </div>
    

 
    
    {/* <Footer/> */}
    </div>

    
  );
}

export default Thanks;
