import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../pages/Auth/_redux/authRedux";
import { profileSlice } from "../pages/App/home/_redux/home/Slice";
// import { AlertSlice } from "../app/modules/Incidents/_redux/incidents/Slice";
// import {AccountSlice} from "../app/modules/Agents/_redux/accounts/Slice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  home: profileSlice.reducer,
  // alert: AlertSlice.reducer,
  // accounts: AccountSlice.reducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
