import { Switch } from "react-router-dom";
import MainApp from "./";

export function RoutesStack() {


  return (
    <Switch>
      <MainApp />
      {/* {!isAuthorized ? (
                 <Route>
                     <AuthPage />
                 </Route>
             ) : (
                 <Redirect from="/auth" to="/"/>
                //  history.push('/')
             )}
 
            <Route exact path="/" component={MainApp}/>
            <Route path="/auth/login" component={AuthPage}/>
            <Route path="/error" component={() => <div>Page not found</div>}/>
            <Route path="/logout" component={Logout}/>
            <Route path="/not_support" component={AppPage}/>
             
 
             {!isAuthorized ? (
                 <Redirect to="/auth/login"/>
                //  history.push('/auth/login')
             ) : (
                //  <>
                     <MainApp/>
                //  </>
             )} */}
    </Switch>
  );
}
