/**
 * Entry application component used to compose providers and render Routes.
 * */

 import React from "react";
 import { Provider } from "react-redux";
 import { BrowserRouter } from "react-router-dom";
 import { PersistGate } from "redux-persist/integration/react";
 import { RoutesStack } from "./pages/Routes";
//  import { LayoutSplashScreen, MaterialThemeProvider } from "../_metronic/layout";
 
 export default function App({ store, persistor, basename }) {
   return (
     /* Provide Redux store */
     <Provider store={store}>
       <PersistGate persistor={persistor} loading={<div/>}>
         {/* <React.Suspense fallback={<div/>}> */}
           {/* <BrowserRouter basename={basename}> */}
              <RoutesStack />
           {/* </BrowserRouter> */}
         {/* </React.Suspense> */}
       </PersistGate>
     </Provider>
   );
 }
 