 import React from "react";
 import ReactDOM from "react-dom";
 import axios from "axios";
 import * as _redux from "./redux";
 import store, { persistor } from "./redux/store";
 import App from "./App";
 import { BrowserRouter } from "react-router-dom";
 
 const PUBLIC_URL = process.env.URL;
 _redux.setupAxios(axios, store);
 
 ReactDOM.render(
    <BrowserRouter>
      <App store={store} persistor={persistor} basename={PUBLIC_URL} />
    </BrowserRouter>,
   document.getElementById("root")
 );
 