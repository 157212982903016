import axios from "axios";

import config from "../../../config";

export const URL = config.URL;

export const LOGIN_URL = URL+"auth/login";
export const REGISTER_URL = URL+"signup";
export const REQUEST_PASSWORD_URL = URL+"api/auth/forgot-password";
export const ME_URL = URL+"user/profile";

export function login(data) {
  return axios.post(LOGIN_URL, data);
}

export function resend_confirmation(data) {
  return axios.post(`${URL}auth/resend-confirm-code`, data);
}

export function send_pin(data) {
  return axios.post(`${URL}auth/confirm-code`, data);
}


export function send_pin_forgot(data) {
  return axios.post(`${URL}auth/forgot-password`, data);
}

export function reset_password(data) {
  return axios.post(`${URL}auth/reset-password`, data);
}

export function editUserData(body) {
  return axios.put(URL+'auth/user', body);
}

export function register(data) {
  return axios.post(REGISTER_URL, data);
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}


export function changePassword(body) {
  return axios.post(URL+'auth/user/change_pass', body);
}


export function refresh_token() {
  return axios.post(URL+'auth/refresh-token', {});
}

export function request_partner(id) {
  return axios.get(URL+'partners/users/'+id);
}

export function getUserByToken() {
  return axios.get(ME_URL);
}
